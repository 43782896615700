@import "../abstracts";

// This class should be added to all topper components to give default theme
// color boilerplate and consistent space for the site navigation.
.hbs-topper-wrapper {
  background-color: $c-bg;
  position: relative;

  &::before {
    content: "";
    @include absolute(x 0 100%);
    height: $nav-bg-height;
    background-color: $c-bg;
  }
}
