@mixin button($theme: default) {
  @include baseline-text($ff-sans);
  align-items: baseline;
  border-radius: $border-radius;
  cursor: pointer;
  display: inline-flex;
  flex-shrink: 0;
  font-weight: $fw-semibold;
  padding: 12px 20px;
  text-decoration: none;
  transition: $transition;

  @if $theme == strong {
    background-color: $c-spot;
    color: $c-theme-solid-inverse;

    @include hover {
      background-color: $c-theme-solid;
      color: $c-theme-solid-inverse;
    }
  } @else {
    color: $c-text;
    background-color: $c-theme-solid-inverse;

    @include hover {
      background-color: $c-spot;
      color: $c-theme-solid-inverse;
    }
  }
}
