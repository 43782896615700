@import "../../../styles/base/abstracts";

.hbs-logo {
  height: auto;
  width: 100%;

  .hbs-logo__separator,
  .hbs-logo__wordmark {
    fill: $c-text;
  }
}
