@mixin checkbox-box($bg: $c-white) {
  @include size(1.2em);
  align-items: center;
  background-color: $bg;
  border-radius: $border-radius;
  border: $border;
  border-color: $c-border-dark;
  cursor: pointer;
  display: inline-flex;
  flex-shrink: 0;
  justify-content: center;
  position: relative;
  transition:
    background-color $transition,
    border-color $transition;
  vertical-align: middle;

  [data-state="checked"] {
    align-items: center;
    display: flex;
  }
}

@mixin checkbox-checked {
  background-color: $c-spot;
  border-color: transparent;
  outline-offset: 2px;

  + label {
    font-weight: $fw-semibold;
  }
}

@mixin checkbox-icon {
  @include size(12px, 10px);
  color: $c-bg;
}

@mixin checkbox-label($spacing: $spacing-xxs, $font-weight: $fw-reg) {
  @include small-title;
  @include subtle-link($c-text, $c-spot);
  cursor: pointer;
  font-weight: $font-weight;
  margin-left: $spacing;
}
