@use "sass:math";
@import "../abstracts";

html {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
  scroll-behavior: smooth;
  text-size-adjust: 100%;
  font-size: $fs-sm;

  ::-moz-selection {
    background: $c-spot;
    color: $c-bg;
    text-shadow: none;
  }

  /* stylelint-enable */

  ::selection {
    background: $c-spot;
    color: $c-bg;
    text-shadow: none;
  }

  :focus,
  :focus-visible {
    outline: $outline;
  }
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  background-color: $c-bg;
  color: $c-text;
  font-family: $ff-sans;
  font-size: $fs-base;
  line-height: $lh-lg;
  margin: 0;
  max-width: 100vw;
  overflow-x: hidden;
  padding: 0;
}

img,
svg,
video {
  max-width: 100%;
  vertical-align: middle;
}

a,
button,
summary {
  cursor: pointer;
}

figcaption {
  margin-bottom: 0;
}

button,
input,
textarea,
select {
  @include reset-input;
}

.hbs-global-visually-hidden {
  @include hide;
}

a.hbs-global-visually-hidden.skipto:focus:focus-visible {
  width: auto;
  height: auto;
  clip: unset;
  background-color: white;
  padding: 12px;
  z-index: 999;
  margin: 0px;
  outline-offset: -8px;
  text-decoration: none;
  outline: $outline;
}

a.hbs-global-visually-hidden.skiptoleftnav:focus,
a.hbs-global-visually-hidden.skiptoend:focus {
  transform: translate(0, -2em);
  width: auto;
  height: auto;
  clip: unset;
  background-color: white;
  padding: 3px;
  z-index: 999;
  margin: 0px 0px 10px 0px;
  outline-offset: 0px;
  outline: $outline;
}

sup,
sub,
small {
  font-size: 0.55em;
}

.hbs-page {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  min-height: 100vh;
}

.hbs-global-align-wide {
  @include mq($bp-desktop) {
    margin-right: calc(#{nested-cols(3, -9)} - #{$grid-padding * 0.6666666});
  }

  @include mq($bp-desktop-xl) {
    margin-right: calc(#{nested-cols(4, -8)} - #{$grid-padding});
  }
}

.hbs-global-align-edge-to-edge {
  @include breakout-full;
}

.hbs-global-align-full {
  @include mq($bp-desktop) {
    margin-left: calc((100% + $grid-padding * 2) * (-4 / 9));
    margin-right: calc((100% + $grid-padding * 2) * (-3 / 9));
  }

  @include mq($bp-desktop-xl) {
    margin-left: calc(#{nested-cols(4, -8)} - #{$grid-padding});
    margin-right: calc(#{nested-cols(4, -8)} - #{$grid-padding});
  }

  .hbs-media-asset__caption {
    @include grid-parent;
    @include site-max-width;
  }

  .hbs-media-asset__caption-text {
    @include grid-child;

    p {
      max-width: 40em;
    }
  }
}

.hbs-global-align-edge-to-edge,
.hbs-global-align-center,
.hbs-global-align-wide,
.hbs-global-align-full {
  @include block-spacing(both, margin, lg);
  position: relative;
  z-index: 10;

  .hbs-component--component-header + & {
    margin-top: 0;
  }

  &.hbs-component--component-header,
  &.hbs-component--section-header {
    margin-bottom: 0;
  }

  .hbs-component--section-header + &:not(.hbs-supporting-details-block) {
    margin-top: 2 * $spacing-xs;
    @include mq($bp-full-grid) {
      margin-top: $spacing-xl;
    }
  }

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }

  h1 + &,
  h2 + &,
  h3 + &,
  h4 + &,
  h5 + &,
  h6 + & {
    margin-top: $spacing-lg;
  }
  .hbs-copy-link-heading__button + & {
    margin-top: $spacing-md;
  }
}

.hbs-global-align-left {
  clear: both;
  float: left;
  margin-right: $grid-padding-mobile;
  margin-top: 1em;
  margin-bottom: 1em;
  padding-right: $grid-padding-mobile;
  padding-top: 0.4em;
  width: $col-8;

  @include mq($bp-tablet) {
    margin-right: $grid-padding;
    padding-right: $grid-padding;
  }
}

.hbs-global-align-right {
  clear: both;
  float: right;
  margin-left: $grid-padding-mobile;
  margin-top: 1em;
  margin-bottom: 1em;
  padding-left: $grid-padding-mobile;
  padding-top: 0.4em;
  width: $col-8;

  @include mq($bp-tablet) {
    margin-left: $grid-padding;
    padding-left: $grid-padding;
  }

  @include mq($bp-desktop) {
    margin-right: calc(
      #{math.div($col-3, $col-9) * -100%} - #{math.div($grid-padding, 3) * 2}
    );
    width: #{math.div($col-5, $col-9) * 100%};
  }

  @include mq($bp-desktop-xl) {
    margin-right: calc(#{math.div($col-4, $col-8) * -100%} - #{$grid-padding});
    width: $col-8;
  }
}

.hbs-has-drop-cap {
  @include dropcap;
}

///////////////////////////////
/// HBS Topper
///////////////////////////////
.hbs-topper {
  @include topper-spacing;
  padding-bottom: $grid-padding-mobile * 2;

  @include mq($bp-tablet) {
    padding-bottom: $grid-padding * 2;
  }
}

///////////////////////////////
/// Themes
///////////////////////////////

// Apply background and text color to themed elements
[data-theme] {
  background-color: var(--c-bg);
  color: var(--c-text);
}

// Light Theme (default)

:root,
.hbs-page {
  --block-spacing-sm: clamp(#{$spacing-sm}, 2.5vw, #{$spacing-md});
  --block-spacing-md: clamp(#{$spacing-xl}, 2.5vw, #{$spacing-xxl});
  --block-spacing-lg: clamp(#{$spacing-xxl}, 5vw, #{$spacing-xxxl});
  --block-spacing-xl: clamp(#{$spacing-xxl}, 10vw, 100px);

  --block-spacing-offset-sm: clamp(-#{$spacing-md}, -2.5vw, -#{$spacing-sm});
  --block-spacing-offset-md: clamp(-#{$spacing-xxl}, -2.5vw, -#{$spacing-xl});
  --block-spacing-offset-lg: clamp(-#{$spacing-xxxl}, -5vw, -#{$spacing-xxl});
  --block-spacing-offset-xl: clamp(-100px, -10vw, -#{$spacing-xxl});
}

:root,
[data-theme="light"] {
  @include theme-light;
}

// Dark Theme
[data-theme="dark"] {
  @include theme-dark;
}

// White Theme
[data-theme="white"] {
  @include theme-white;
}

// Black Theme
[data-theme="black"] {
  @include theme-black;
}

// Crimson Theme
[data-theme="crimson"] {
  @include theme-crimson;
}

// Purple Theme
[data-theme="purple"] {
  @include theme-purple;
}

// Blue Theme
[data-theme="blue"] {
  @include theme-blue;
}

// Red Theme
[data-theme="red"] {
  @include theme-red;
}

// Plain Light Theme
[data-theme="plain-light"] {
  @include theme-plain-light;
}

// Plain Dark Theme
[data-theme="plain-dark"] {
  @include theme-plain-dark;
}

.hbs-collapse-bottom-margin {
  margin-bottom: 0;
}

#skipto-main,
#skipto-body {
  &:focus:not(:focus-visible) {
    outline: 0;
  }
}
