// IMPORTANT!
// If updating this file make sure to update form-assembly.scss and reupload it to Form Assembly

@import "../../../styles/base/abstracts";

.hbs-form,
.wForm,
.form_container {
  @include rich-text;

  p,
  fieldset {
    margin: $font-margin-h6;
  }
}

.hbs-form__group {
  margin-bottom: $spacing-md;
}

.hbs-form label,
.wForm .labelsAbove .preField,
.wForm label {
  @include xs-title;
  letter-spacing: 0.05em;
  text-transform: uppercase;
}

.hbs-form .hbs-form__row {
  display: flex;
  flex-direction: column;
  gap: $grid-padding-mobile * 2;
  width: 100%;

  @include mq($bp-tablet) {
    gap: $grid-padding * 2;
    flex-direction: row;
  }
}

.hbs-form input[type="text"],
.hbs-form input[type="email"],
.hbs-form input[type="number"],
.hbs-form input[type="password"],
.hbs-form input[type="search"],
.hbs-form input[type="tel"],
.hbs-form input[type="url"],
.hbs-form textarea,
.hbs-form-select select,
.wForm input:not([type="submit"]),
.wForm textarea,
.wForm select,
.wForm .oneField input,
.wFormContainer .wForm textarea {
  @include reset-input;
  @include baseline-text;
  @include padding($spacing-sm);
  background-color: $c-theme-solid-inverse;
  border-radius: $border-radius;
  border: $border;
  border-color: $c-border-dark;
  color: $c-text;
  transition:
    border-color $transition,
    background-color $transition;
  width: 100%;

  &::placeholder {
    color: $c-text-light;
  }

  &:focus:not(:focus-visible) {
    outline: none;
    border-color: $c-text;

    &::placeholder {
      color: $c-text-lighter;
    }
  }
  &:focus:focus-visible {
    outline-offset: -7px;
  }

  &.hbs-form-input--large {
    @include body-text($ff-sans);

    @include mq($bp-desktop) {
      @include padding($spacing-md);
    }
  }
}

.hbs-form input[type="search"] {
  position: relative;

  &::-webkit-search-cancel-button {
    @include size(1.5em);
    appearance: none;
    background-color: $c-bg;
    background-image: url("data:image/svg+xml,%3Csvg width='15' height='15' viewBox='0 0 15 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.8536 2.85355C13.0488 2.65829 13.0488 2.34171 12.8536 2.14645C12.6583 1.95118 12.3417 1.95118 12.1464 2.14645L7.5 6.79289L2.85355 2.14645C2.65829 1.95118 2.34171 1.95118 2.14645 2.14645C1.95118 2.34171 1.95118 2.65829 2.14645 2.85355L6.79289 7.5L2.14645 12.1464C1.95118 12.3417 1.95118 12.6583 2.14645 12.8536C2.34171 13.0488 2.65829 13.0488 2.85355 12.8536L7.5 8.20711L12.1464 12.8536C12.3417 13.0488 12.6583 13.0488 12.8536 12.8536C13.0488 12.6583 13.0488 12.3417 12.8536 12.1464L8.20711 7.5L12.8536 2.85355Z' fill='currentColor' fill-rule='evenodd' clip-rule='evenodd'%3E%3C/path%3E%3C/svg%3E");
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 50%;
    cursor: pointer;

    @include hover {
      background-color: $c-cream;
    }
  }
}

.hbs-form textarea,
.wForm textarea {
  @include padding($spacing-md);
  resize: none;
}

.hbs-form input[type="radio"],
.wForm input[type="radio"] {
  @include baseline-text;
  @include margin($spacing-xxs);
  @include size(1.5em);
  background-color: $c-theme-solid-inverse;
  border: $border;
  border-color: $c-border-dark;
  border-radius: 50%;
  cursor: pointer;
  flex-shrink: 0;
  vertical-align: middle;
  position: relative;

  &:checked {
    &::before {
      @include pseudo(0);
      @include size(100%);
      background-color: $c-spot;
      border-radius: 50%;
      border: 3px solid $c-theme-solid-inverse;
    }
  }

  + label {
    margin-right: $spacing-lg;
  }
}

.hbs-form input[type="checkbox"],
.wForm input[type="checkbox"] {
  @include checkbox-box($c-theme-solid-inverse);

  &:checked {
    @include checkbox-checked;

    &::before {
      @include pseudo(50% x x 50%);
      @include size(60%, 30%);
      border-left: $border-solid;
      border-bottom: $border-solid;
      border-width: 0.125em;
      border-color: $c-bg;
      transform: translate(-50%, -70%) rotateZ(-35deg);
    }
  }

  + label {
    margin-left: $spacing-xxs;
    margin-right: $spacing-lg;
  }
}

.hbs-form input[type="submit"],
.wForm input[type="submit"] {
  @include button(strong);
}

.hbs-form-select,
// TODO: :has is not supported in Firefox
.wForm .inputWrapper:has(select) {
  position: relative;
  width: 100%;

  &::before {
    @include pseudo(50% 0 x x);
    @include size(0.5em);
    @include margin(x $spacing-md);
    border-right: $border-solid;
    border-bottom: $border-solid;
    pointer-events: none;
    transform: translateY(-70%) rotateZ(45deg);
  }

  select {
    cursor: pointer;
    font-weight: $fw-semibold;
    padding-right: 1em;
  }
}

// spot adjustments for crimson theme
[data-theme="crimson"] {
  .hbs-form input[type="text"],
  .hbs-form input[type="email"],
  .hbs-form input[type="number"],
  .hbs-form input[type="password"],
  .hbs-form input[type="search"],
  .hbs-form input[type="tel"],
  .hbs-form input[type="url"],
  .hbs-form textarea,
  .hbs-form input[type="checkbox"],
  .hbs-form input[type="radio"],
  .hbs-form-select select {
    @include theme-light;
    border: transparent;
  }

  .hbs-form input[type="radio"]:checked::before,
  .hbs-form input[type="checkbox"]:checked {
    background-color: $c-bg-inverse;
  }
}

// Form Assembly overrides
.wForm {
  padding: 0 !important;
}

.wForm .oneField input,
.wForm input[type="text"],
.wForm input[type="email"],
.wForm input[type="number"],
.wForm input[type="password"],
.wForm input[type="search"],
.wForm input[type="tel"],
.wForm input[type="url"],
.wForm textarea {
  border-color: $c-border-dark !important;
}

.wForm .oneField {
  padding: 0 !important;
}

.wForm .inputWrapper {
  display: block !important;
}

.wForm input:not([type="submit"]) {
  @include baseline-text(
    $font-family: $ff-sans !important,
    $font-weight: $fw-reg !important,
    $font-size: $fs-sm !important
  );
  margin: 0 !important;
  padding: $spacing-sm !important;
}

.hbs-form .wForm form .oneChoice label,
.hbs-form .wForm form .oneChoice .label {
  vertical-align: middle;
}

.hbs-form .wFormContainer {
  max-width: none !important;
}

.wForm form > div.oneField {
  line-height: 1;
  margin-top: 0 !important;
  margin-bottom: $spacing-md;
}

.wFormContainer .wForm form .oneChoice label,
.wFormContainer .wForm form .oneChoice .label {
  vertical-align: middle;
}

.wFormContainer .wForm .hint {
  @include small-text;
}

// Validation

.hbs-form .field-error,
.wForm .field-error {
  label {
    color: $c-crimson;
  }

  input[type="text"],
  input[type="email"],
  input[type="number"],
  input[type="password"],
  input[type="search"],
  input[type="tel"],
  input[type="url"],
  textarea {
    border: 1px solid $c-crimson !important;
  }
}

.hbs-form-select .field-error select,
.wForm .field-error select {
  border: 1px solid $c-crimson !important;
}

.hbs-form,
.wForm {
  .error-placeholder {
    .required {
      @include small-text;
      color: $c-crimson;
      margin-top: $spacing-xs;
    }
  }
}
